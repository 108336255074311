<template>
  <div>
    <div class='log' v-if="userPermissions.indexOf('artist_log') != -1">
      <el-collapse v-model='activeNames' @change='handleChange'>
        <el-collapse-item title='操作日志' name='1'>
          <el-button type='text' icon='el-icon-refresh' @click='getList' style='float: left'>刷新日志</el-button>

          <div class='default-table'>
            <!--    渲染表格-->
            <m-table :showIndex='true' :offset='offset' max-height='800' :tableData='dataList' :columns='columns'
                     :loading='loadingStatus' border>

            </m-table>
            <!--    渲染分页-->
            <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                          @pagination='getList'/>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div v-else>
      暂无查看操作日志权限
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ArtistLog',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  props: {
    artistId: {
      type: String,
      default() {
        return ''
      }

    }
  },
  data() {
    return {
      loadingStatus: false,
      activeNames: ['1'],
      offset: 0,
      total: 0,
      pagingData: {
        page_size: 10,
        current_page: 1
      },
      dataList: [],//日志
      columns: [
        {
          title: '日志内容',
          value: 'message',
          width: 400,
          align: 'left'
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 120
        },
        {
          title: '修改时间',
          value: 'updated_at',
          width: 120
        }
      ]
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleChange() {
      // console.log(val);
      this.getList()
    },
    async getList() {
      this.loadingStatus = true
      this.dataList = []
      if (this.artistId) {
        let { list, pages } = await this.$api.getArtistLog(Object.assign({ artist_id: this.artistId }, this.pagingData))
        this.loadingStatus = false
        this.$nextTick(() => {
          this.dataList = list
          this.total = pages.total
          this.offset = pages.offset
          this.pagingData.current_page = pages.current_page
          this.pagingData.page_size = pages.page_size
        })
      }
    }
  }
}
</script>

<style scoped>

.log-message {
  font-style: initial;
  margin-left: 10px;
  font-size: 12px;
}
</style>
